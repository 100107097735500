import React, { useRef } from "react"

export default () => {
  return (
    <blockquote className="twitter-tweet">
      <p lang="en" dir="ltr">
        We are building an entire product from scratch in 72 hours and are
        *streaming the whole time*, starting in 15 minutes! 😱
        <br />
        <br />
        Tune in live at{" "}
        <a href="https://t.co/9zEzCbeUEi">https://t.co/9zEzCbeUEi</a> 🌿
        <br />
        <br />
        Here is what we look like before this weekend. Let&#39;s see what we
        look like after 😉{" "}
        <a href="https://t.co/LCYrUssRGQ">pic.twitter.com/LCYrUssRGQ</a>
      </p>
      &mdash; Max Stoiber 🐟 (@mxstbr){" "}
      <a href="https://twitter.com/mxstbr/status/1281616190574600192?ref_src=twsrc%5Etfw">
        July 10, 2020
      </a>
    </blockquote>
  )
}
