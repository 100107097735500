import React from "react"

export default ({ username, name }) => (
  <a
    href={`https://twitter.com/${username}`}
    data-splitbee-event="Click Twitter"
    data-splitbee-event-username={username}
    style={{
      display: `flex`,
      flexDirection: `column`,
      alignItems: `center`,
      color: `white`,
      textDecoration: `none`,
    }}
  >
    <img
      src={`https://twitter-avatar.now.sh/${username}`}
      style={{ borderRadius: "50%", height: `40px` }}
    />
    <span style={{ fontSize: `0.75em`, marginTop: `0.5em` }}>{name}</span>
    <span
      style={{
        fontSize: `0.75em`,
        lineHeight: 1,
        color: `rgba(255,255,255,0.5)`,
      }}
    >
      @{username}
    </span>
  </a>
)
