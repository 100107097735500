import React, { useState } from "react"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import StreamEmbed from "../components/StreamEmbed"
import TwitterAvatar from "../components/TwitterAvatar"

const isLive = true

// Just for syntax highlighting
const css = strings => strings.join("\n")

export default function Home({ data }) {
  const [formStatus, setFormStatus] = useState<
    "loading" | "success" | "error" | null
  >(null)

  const onSubmit = async e => {
    e.preventDefault()
    setFormStatus("loading")
    const formData = new FormData(e.currentTarget)

    const data = {}
    formData.forEach(function (value, key) {
      data[key] = value
    })

    const response = await fetch(
      "https://api.buttondown.email/v1/subscribers",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token 06dd8c0a-6d97-4e2a-808b-e975a0eea2d4",
        },

        body: JSON.stringify(data),
      }
    )

    const success = response.status === 201
    setFormStatus(success ? "success" : "error")

    if (success && typeof splitbee !== "undefined") {
      splitbee?.track("Submitted Form")
    }
  }
  return (
    <>
      <Helmet>
        <style>
          {css`
            * {
              box-sizing: border-box;
            }

            html,
            body {
              background-color: black;
              font-family: "Inter", -apple-system, BlinkMacSystemFont,
                "Segoe UI", Roboto, Helvetica, Arial, sans-serif,
                "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
              line-height: 1.6;
              font-size: 16px;
              -webkit-font-smoothing: antialiased;
            }

            html,
            body,
            #___gatsby,
            #gatsby-focus-wrapper {
              height: 100%;
              width: 100%;
            }

            .content-wrapper {
              justify-content: space-between;
            }

            @media screen and (min-width: 700px) {
              body {
                font-size: 20px;
              }

              .content-wrapper {
                justify-content: center;
              }
            }

            input {
              transition: all ease-in-out 80ms;
              box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.3);
            }

            input:focus {
              outline: 0;
              box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.4);
            }

            input[type="submit"] {
              background: #4a6349;
            }

            input[type="submit"]:hover,
            input[type="submit"]:focus {
              background: #5f7d5f;
            }

            h1,
            h2,
            h3 {
              line-height: 1.2;
            }

            img {
              user-select: none;
            }
          `}
        </style>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        <title>Product Weekend Live</title>
        <meta name="title" content="Product Weekend Live" />
        <meta
          name="description"
          content="Watch us build a product in one weekend. 10. - 13. July, live on all streaming platforms."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://metatags.io/" />
        <meta property="og:title" content="Product Weekend Live" />
        <meta
          property="og:description"
          content="Watch us build a product in one weekend. 10. - 13. July, live on all streaming platforms."
        />
        <meta
          property="og:image"
          content="https://productweekend.live/social-media.png"
        ></meta>
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://metatags.io/" />
        <meta property="twitter:title" content="Product Weekend Live" />
        <meta
          property="twitter:description"
          content="Watch us build a product in one weekend. 10. - 13. July, live on all streaming platforms."
        />
        <meta
          property="twitter:image"
          content="https://productweekend.live/social-media.png"
        ></meta>
        <link
          rel="stylesheet"
          type="text/css"
          href="https://unpkg.com/normalize.css@8.0.1/normalize.css"
        />
        <link rel="icon" type="image/png" href="/fern-icon.png" />
        <script src="https://embed.twitch.tv/embed/v1.js"></script>
        <script
          async
          src="https://platform.twitter.com/widgets.js"
          charSet="utf-8"
        ></script>
      </Helmet>
      <Img
        fluid={data.file.childImageSharp.fluid}
        alt=""
        style={{
          position: `absolute`,
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          height: `100%`,
          width: `100%`,
        }}
      />
      <div
        style={{
          position: `absolute`,
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          height: `100%`,
          width: `100%`,
          backgroundColor: `rgba(0, 0, 0, 0.5)`,
        }}
      />
      <div
        style={{
          color: `#FFF`,
          display: `flex`,
          flexDirection: `column`,
          height: `100%`,
          justifyContent: `space-between`,
          alignItems: `center`,
          position: `relative`,
        }}
      >
        <div
          style={{
            width: `100%`,
            display: `flex`,
            justifyContent: `space-between`,
            alignItems: `center`,
            fontSize: `0.75em`,
            padding: `1.625em`,
          }}
        >
          <div>
            <span
              style={{
                height: `0.625em`,
                width: `0.625em`,
                backgroundColor: isLive ? `#F45A5A` : `#7D7D7D`,
                borderRadius: `50%`,
                display: `inline-block`,
                marginRight: `0.625em`,
              }}
            />
            {isLive ? "Live" : "Offline"}
          </div>
        </div>
        <div
          className="content-wrapper"
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            width: "100%",
            alignItems: "center",
          }}
        >
          <div></div>
          <div style={{ textAlign: `center` }}>
            <h1
              style={{
                fontSize: `1.5em`,
                textAlign: `center`,
                lineHeight: 1.25,
                margin: 0,
                marginBottom: `0.25em`,
              }}
            >
              Thousands of people watched us
              <br />
              <span style={{ fontWeight: 400 }}>
                build a product in one weekend.
              </span>
            </h1>
            <StreamEmbed />
            <div style={{ display: `flex`, flexDirection: `column` }}>
              <div
                style={{
                  color: `rgba(255, 255, 255, 0.8)`,
                  fontWeight: 400,
                  fontSize: "0.75em",
                }}
              >
                Get notified when we do this again
              </div>
              <form
                className="embeddable-buttondown-form"
                onSubmit={onSubmit}
                style={{
                  marginTop: "1em",
                }}
              >
                <input
                  type="email"
                  placeholder="you@example.com"
                  name="email"
                  id="bd-email"
                  style={{
                    border: 0,
                    padding: "15px",
                    borderRadius: "4px",
                    fontSize: "0.75em",
                    color: "white",
                    height: 47,

                    background: "rgba(255,255,255,0.2)",
                    marginRight: "0.5em",
                  }}
                />
                <input type="hidden" value="1" name="embed"></input>
                <input
                  type="submit"
                  value={
                    formStatus === "loading" ? "Loading ..." : "Get Notified"
                  }
                  style={{
                    border: 0,
                    color: "white",
                    fontSize: "0.75em",
                    padding: "15px",
                    borderRadius: 4,
                    height: 47,
                  }}
                ></input>
                <div
                  style={{
                    fontSize: "0.75em",
                    marginTop: "0.5em",
                    opacity: formStatus === "success" ? 0.8 : 0,
                    transition: "opacity 0.5s ease-in-out",
                  }}
                >
                  Successfully subscribed!
                </div>
              </form>
            </div>
          </div>

          <div
            style={{
              display: `flex`,
              marginTop: "2em",
              justifyContent: `space-around`,
              width: `100%`,
              maxWidth: `30em`,
              padding: `1.625em`,
            }}
          >
            <TwitterAvatar username="mxstbr" name="Max" />
            <TwitterAvatar username="linstobias" name="Tobi" />
            <TwitterAvatar username="timolins" name="Timo" />
            <TwitterAvatar username="lauridskern" name="Laurids" />
          </div>
        </div>
      </div>
    </>
  )
}

export const query = graphql`
  query {
    file(relativePath: { eq: "fern.png" }) {
      childImageSharp {
        fluid(pngQuality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
